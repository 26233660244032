<template>
  <v-app>
    <Header></Header>
    <v-card
        elevation="10"
        outlined
        class="card"

    >
      <v-card-title class="main_heading">
        <div><b>TERMS & CONDITIONS OF USE</b></div>
      </v-card-title>
      <div class="content">
        Last updated: 1st January, 2022
        <br><br>
        By accessing or using our Website located at
        <router-link to="/" style="text-decoration: none;"><b>https://shopactreg.in</b></router-link>
        (“Website”) in any way or downloading, installing or
        using our website server including but not limited to our products or accessing software supplied (collectively,
        the “Services”) by Osmium Consultancy (hereafter referred as Osmium Consultancy, us, we, our) or clicking on a button or taking
        similar action to signify your affirmative acceptance of this Agreement, you hereby represent that:<br><br>

        (i) You have read, understood, and agree to be bound by this Agreement and any future amendments and additions
        to
        this Agreement as published from time to time at
        <router-link to="/" style="text-decoration: none;"><b>https://shopactreg.in</b></router-link>
        <br><br>

        (ii) You are of legal age in the jurisdiction in which you reside to form a binding contract with Osmium Consultancy<br><br>

        Services provided by  Osmium Consultancy shall be included but not limited to technology platforms such as Website or that shall
        enable the users to submit their User data for getting consultancy on application of Shopact or any other
        services
        offered through the URL
        <router-link to="/" style="text-decoration: none;"><b>https://shopactreg.in</b></router-link>
        ; wherein a user can provide their user data and request consultancy for
        applying a new shopact or renewal of shopact by paying our consultancy charges.<br><br>

        The terms “you,” “user” and “users” refer to all individuals and other persons who access or use of our
        services,
        including, without limitation, any companies, organizations, or other legal entities that register accounts or
        otherwise access or use the services through their respective employees, agents or representatives. Except as
        otherwise provided herein, <b>IF YOU DO NOT AGREE TO BE BOUND BY THE AGREEMENT, YOU MAY NOT ACCESS OR USE THE
        WEBSITE</b><br><br>


        <span class="card-internal-heading">1. General Information</span><br><br>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE (“Agreement”) CAREFULLY. BY USING THE SERVICES, YOU AGREE TO BE
        BOUND BY ALL OF THE TERMS AND CONDITIONS OF USE MENTIONED BELOW.<br><br>

        <b>1.1</b> These Terms & Conditions of Use constitute a legally binding Agreement between you and  Osmium Consultancy, having its
        office
        at Cidco Near High Court, Aurangabad, regarding your use of the Services.<br>

        <b>1.2</b> These Terms & Conditions of Use (“Terms,” including our Privacy Policy, Cookie Policy and all other
        policies
        on our Website) define the terms and conditions under which you are allowed to use our services, and how we will
        treat your account with us.<br><br>

        <span class="card-internal-heading">2. Definitions</span><br>
        <b>2.1</b> Intellectual Property Rights mean and include without limitation all copyrights, patents, trademarks,
        trade
        secrets and other related documents and shall remain, the sole and exclusive property of Osmium Consultancy <br>

        <b>2.2</b> Third parties include but are not limited to developer, hosting, google analytics & search engine
        providers,
        payment providers.<br>

        <b>2.3</b> “User(s)” (hereinafter collectively referred to as “You”, “Your”, “User”), means our user(s) who use
        our
        Services.<br>

        <b>2.4</b> “User Content” means all electronic data, text, messages or other materials, including personal data
        of
        Users, submitted to the Service(s) by You in connection with Your use of the Service(s).<br>

        <b>2.5</b> “Applicable Data Protection Law” means Information Technology Act 2008 (IT Act)<br>
        <br><br>

        <span class="card-internal-heading">Scope:</span><br>
        <span style="text-align: center">This term of use covers the below mentioned:</span><br><br>
        Eligibility<br>
        Contractual Relationship<br>
        License Grant<br>
        Content Rights<br>
        Third Party Interaction<br>
        Prohibited Use<br>
        User Data Access<br>
        User Requirements & Conduct<br>
        Payment and Commercial Terms<br>
        Intellectual Property Rights<br>
        Third Party Intellectual Property Rights<br>
        Notice of Copyright or Intellectual Property Infringement<br>
        Text Messaging & Promotional Codes<br>
        Text Messaging & Promotional Codes<br>
        Disclaimers & Limitation of Liability<br>
        Indemnity<br>
        Severability<br>
        Changes to Terms & Conditions<br>
        Entire Agreement<br>
        Governing Laws<br>
        Compliance with laws<br>
        Disputes<br>
        Binding Individual Arbitration<br>
        Other Provisions<br>
        Cookies<br>
        Updates to services<br>
        No Changes in Terms at your request<br>
        Contact Information<br>
        <br><br>

        <span class="card-internal-heading">3. Eligibility</span><br><br>
        To use our services, you must:<br>
        <b>3.1</b> Be at least Eighteen (18) years old.<br>
        <b>3.2</b> Complete the filling form process;<br>
        <b>3.3</b> Agree to the Terms and Conditions<br>
        <b>3.4</b> Provide true, complete and up to date contact information.<br>
        By using our services, you represent and warrant that you meet all the requirements listed above and that you
        won’t use our services in a way that violates any laws or regulations. (Representing and warranting is like
        making a legally enforceable promise.)<br>.Osmium Consultancy may refuse service, close accounts of any users, and change
        eligibility requirements at any time.<br><br>

        <span class="card-internal-heading">4. Contractual Relationship</span><br><br>
        <b>4.1</b>  Osmium Consultancy may terminate any of these terms or any services with respect to you, or generally, cease offering
        or
        deny access to the services or any portion thereof, at any time for any reason.<br>

        <b>4.2</b> Supplemental Terms may apply to certain services, such as policies for any particular event, activity
        or
        promotion, and such supplemental terms will be published on our services in connection with the applicable
        services from time to time. Supplemental Terms are in addition to and shall be considered a part of the Terms
        and Conditions of Use the purposes of the applicable services. Supplemental Terms shall prevail over these Terms
        and Conditions of Use in the event of a conflict with respect to the applicable services.<br>

        <b>4.3</b> Our collection and use of user information in connection with the services is as provided in  Osmium Consultancy
        Privacy
        Policy located at https://shopactreg.in<br>

        <b>4.4</b> In case of incorporation of any new legislation or any amendments to the existing legislation
        governing data
        of any individual, some of the clauses of this Agreement may either be updated or deleted without any notice, to
        comply with the said provisions of the applicable legislation.<br>Hence it is advisable to check this Agreement
        from time to time.<br>
        <br><br>

        <span class="card-internal-heading">5. License Grant</span><br><br>
        <b>5.1</b>  Osmium Consultancy grants you a revocable, non-exclusive, non-transferable, limited license to edit user data on the
        websites, developed strictly in accordance with these Terms.<br>

        <b>5.2</b> You may not copy, decompile our Website or our services.
        <br><br>

        <span class="card-internal-heading">6. Content Rights</span><br><br>
        <b>6.1</b> You own the rights to the user data you provide to  Osmium Consultancy on we don’t claim ownership over any of it.
        However, by providing user data to  Osmium Consultancy, you give us permission to use your data solely to do the things we need
        to do to provide our services, including but not limited to storing, displaying, reproducing, and distributing
        your data. This may include providing your data with third parties for broader broadcast, distribution, or
        publication<br>

        <b>6.2</b> We will never sell your data to third parties<br>

        <b>6.3</b> You’re responsible for the data you submit by using our services and assume all risks associated with
        it.<br>
        <br><br>

        <span class="card-internal-heading">7. Third Party Interactions</span><br><br>
        <b>7.1</b> The services may contain links to third-party Websites(“<b>Third-Party Websites</b>”)<br>

        <b>7.2</b> When you click on a link to a Third-Party Website, we will not warn you that you have left our
        Website, server or services and we will not warn you that you are subject to the Terms and Conditions (including
        privacy policies) of another website or destination. Such Third-Party Websites & Advertisements are not under
        the control of Osmium Consultancy We are not responsible for any Third-Party Websites, Third-Party Applications, or any
        Third-Party Advertisements.<br>

        <b>7.3</b> You use all links in Third-Party Websites & Advertisements at your own risk. You should review
        applicable terms and policies, including privacy and data gathering practices of any Third-Party Websites or
        Third-Party Apps, and make whatever investigation you feel necessary or appropriate before proceeding with any
        transaction with any third party.<br>

        <b>7.4</b> You acknowledge and agree that our services are not responsible or liable for: (i) the availability
        or
        accuracy of such links, Websites/Apps or any other resources; or (ii) the content, products, or services on or
        available from such links Websites/Apps or resources.<br>
        <br><br>

        <span class="card-internal-heading">8. Prohibited Uses</span><br><br>
        You represent and warrant that you will not use our services to:<br><br>
        <b>8.1</b> Violate this Agreement, or any applicable law or regulation, including without limitation to laws
        designed to regulate unsolicited email or other electronic advertising.<br>

        <b>8.2</b> Attempt to probe, scan, test, or violate the security features of our services or of any associated
        system or network, or to obtain unauthorized access to materials or other information stored thereon.<br>

        <b>8.3</b> Share your application number, let anyone access your user data to modify or do anything that might
        put your application at risk.<br>

        <b>8.4</b> Encourage or help anyone do any of the things on this list<br>
        <b>8.4</b> Attempt to interfere with the use of the services by any other manner not expressly mentioned
        above.<br>
        <b>8.4</b> Attempt to gain unauthorized access to or impair any aspect of the services or its related systems or
        networks.
        We reserve the right to cooperate fully in any investigation by law enforcement officials of any violation of
        this Agreement. We also reserve the right to terminate your use of the services for violating any of the
        prohibited uses.<br>
        <br><br>

        <span class="card-internal-heading">9. User Data Access</span><br><br>
        <b>9.1</b> To use most aspects of our services, you must fill up the form and make modification to such
        submitted user data. You must be at least 18 years of age, kindly refer our Privacy policy for more details. If
        you violate this clause then we do not take any liability for the same under any legislation, regulation,
        prevailing rules etc. that govern minors and/or students under the age of 18.<br>

        <b>9.2</b> You agree to submit accurate, complete, and up-to-date user information. Your failure to submit
        accurate, complete, and up-to-date user information, may result in rejection of the application and  Osmium Consultancy will not
        be responsible for the same.<br>
        <br><br>

        <span class="card-internal-heading">10. User Requirements & Conduct</span><br><br>
        <b>10.1</b> You agree that we may at any time, and at our sole discretion, terminate your application without
        prior notice to you and without reimbursement if we suspect a violation any of these Terms and Conditions of
        Use. In addition, you acknowledge that we will cooperate fully with investigations by law enforcement
        authorities.<br>
        <br><br>

        <span class="card-internal-heading">11. Payment and Commercial Terms</span><br><br>
        <b>11.1</b> You agree to pay all Consultation fees associated to application of new or renewed shopact.<br>

        <b>11.2</b> If for any reason, your payment is not received by  Osmium Consultancy, your application will be kept on hold till
        the
        time payment is received by us.<br>
        <br><br>

        <span class="card-internal-heading">12. Intellectual Property Rights</span><br><br>
        <b>12.1</b> Neither your use of the services nor this Agreement grants you any right, title or interest in our
        copyrights, trademarks and patents or the intellectual properties if any owned by us.<br>

        <b>12.2</b>  Osmium Consultancy trademarks and/or service marks may not be used in connection with any product or service that is
        not provided by  Osmium Consultancy, in any manner that is likely to cause confusion among customers or users of the Website,
        tarnishes or dilutes the marks, or disparages or discredits  Osmium Consultancy<br>

        <b>12.3</b> Our services include a user interface that allows you to upload user content and customize certain
        aspects of your content. You are granted a limited right to use those services only in conjunction with the
        services and in accordance with these Terms and Conditions of Use.<br>
        <br><br>

        <span class="card-internal-heading">13. Third Party Intellectual Property Rights</span><br><br>
         Osmium Consultancy deeply respects the third-party intellectual property rights that may be implicated in applying for a
        Shop Act License. These intellectual property rights, and your responsibilities with respect to these rights are
        outlined
        below:<br><br>
        <b>13.1</b> While applying for Shop Act License, using our services, you use your own image content such as
        photos, user
        details, user documents such as pan card, Aadhaar card etc. You retain any and all rights you hold with respect
        to image content and data that you upload.<br>
        <br><br>

        <span class="card-internal-heading">14. Notice of Copyright or Intellectual Property Infringement</span><br><br>
        <b>14.1</b> Please notify us if you believe any of your intellectual property rights have been infringed by a
        User of our services. Please email us at contact.shopactreg@gmail.com for complaints and customer service
        enquiries.<br>
        <br><br>

        <span class="card-internal-heading">15. Text Messaging & Promotional Codes</span><br><br>
        <b>15.1</b> By filling up the form, you agree that we may send you text (SMS) messages or email communications
        as part of the normal business operation of your use of the services. You may opt-out of receiving such
        communication by writing to us at contact.shopactreg@gmail.com . You acknowledge that opting out of such
        communication may impact your use of the services.<br>
        <br><br>

        <span class="card-internal-heading">16. Network Access & Devices</span><br><br>
        <b>16.1</b> Neither your use of the services nor this Agreement grants you any right, title or interest in our
        copyrights, trademarks and patents or the intellectual properties if any owned by us.<br>

        <b>16.2</b> You are responsible for obtaining the data network access necessary to use the services. Your mobile
        network’s data and messaging rates and fees may apply if you access or use the services from a wireless-enabled
        device and you shall be responsible for such rates and fees.<br>

        <b>16.3</b> You are responsible for acquiring and updating compatible hardware or devices necessary to access
        and use the services and any updates thereto. In addition, the services may be subject to malfunctions and
        delays inherent in the use of the Internet and electronic communications.<br>
        <br><br>

        <span class="card-internal-heading">17. Disclaimers & Limitation of Liability</span><br><br>
        <span style="color: red; font-size: xx-large; font-weight: bold;">DISCLAIMER OF WARRANTIES</span><br>
        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.”  Osmium Consultancy DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS,
        IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
        AND NON-INFRINGEMENT.
        <br><br>
        IN ADDITION,  Osmium Consultancy MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY,
        TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR THAT THE SERVICES WILL BE UNINTERRUPTED OR
        ERROR-FREE.
        <br><br>
         Osmium Consultancy DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTIES.
        <br><br>
        YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE REQUESTED IN CONNECTION
        THEREWITH,
        REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.<br><br>

        <span style="color: red; font-size: xx-large; font-weight: bold;">LIMITATION OF LIABILITY</span><br>
        YOUR EXCLUSIVE REMEDY AND  Osmium Consultancy ENTIRE LIABILITY, IF ANY, FOR ANY CLAIMS, ARISING OUT OF THE SERVICES SHALL BE
        LIMITED TO THE AMOUNT YOU PAID TO  Osmium Consultancy, IF ANY, DURING THE SIX-MONTH PERIOD BEFORE THE ACT GIVING RISE TO THE
        LIABILITY.
        <br><br>
        IN NO EVENT SHALL  Osmium Consultancy BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
        CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, THOSE RESULTING
        FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
        ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICES.
        <br><br>
        YOU ARE SOLELY RESPONSIBLE FOR MAKING BACKUP COPIES OF ANY AND ALL OF YOUR CONTENT.  Osmium Consultancy SHALL NOT BE LIABLE FOR
        ANY LOSS OF OR DAMAGE TO YOUR CONTENT.<br>

        <br><br>

        <span class="card-internal-heading">18. Indemnity</span><br><br>
        You acknowledge to defend, indemnify and hold  Osmium Consultancy, its affiliates, subsidiaries, directors, officers, employees,
        agents, partners and any other licensors (each, an “<b>Indemnified Party</b>”) harmless from and against any
        claim,
        disputes or demand, including reasonable attorneys’ fees, made by a third party, relating to, or arising from:
        <br><br>
        <b>18.1</b> Your violation of any third-party right, including without limitation to any right to privacy,
        publicity rights or intellectual property rights, including content the user distributes through the
        services.<br>

        <b>18.2</b> Your wrongful or improper use of the services.<br>

        <b>18.3</b> Your violation of any applicable laws, rules or regulations or any other applicable law through or
        related to the use of our services;<br>

        <b>18.4</b> The indemnifications set forth above will survive the termination or expiration of this Agreement
        and/or your use of the services.<br>
        <br><br>

        <span class="card-internal-heading">19. Severability</span><br><br>
        <b>19.1</b> If any provision of this Agreement is held to be unenforceable or invalid, such provision will be
        changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions of the Agreement will continue in full force and effect.<br>
        <br><br>

        <span class="card-internal-heading">20. Changes to Terms & Conditions</span><br><br>
        <b>20.1</b>  Osmium Consultancy reserves the right to modify the Terms of this Agreement or its policies at any time, effective
        upon posting of an updated version of this Agreement on its services. You should regularly review this
        Agreement, as your continued use of the services after any such changes constitutes your agreement to such
        changes.<br>
        <br><br>

        <span class="card-internal-heading">21. Entire Agreement</span><br><br>
        <b>21.1</b> The Terms, together with any additional terms and conditions incorporated herein or referred to
        herein constitute the entire Agreement between  Osmium Consultancy and you, relating to the subject matter hereof, and supersedes
        any prior understanding or agreements (whether oral or written) regarding the subject matter, and may not be
        amended or modified except in writing or by making such amendments or modifications available on our services.
        <br>
        <br><br>

        <span class="card-internal-heading">22. Governing Laws</span><br><br>
        <b>22.1</b> The Agreement and any dispute arising from the same will be governed by applicable data protection
        law in India and/or applicable as applied to agreements entered into and to be performed entirely within India,
        without regard to its choice of law or conflicts of law principles.<br>
        <br><br>

        <span class="card-internal-heading">23. Compliance with laws</span><br><br>
        <b>23.1</b> You represent and warrant that your use of our services will comply with all applicable data
        protection law/ regulations. You may not use our service for any unlawful or discriminatory activities,
        including acts prohibited by the laws in India.<br>

        <b>23.2</b> You have complied, and will comply, with all regulations, as well as data protection, electronic
        communication, and privacy laws that apply to the countries where you’re sending any form of communication
        through our services.<br>

        <b>23.3</b> Agree to indemnify and hold us harmless from any losses, including attorney fees, that result from
        your breach of any part of these warranties.<br>
        <br><br>

        <span class="card-internal-heading">24. Disputes</span><br><br>
        <b>24.1</b> Disputes are defined as any claim, controversy, or dispute between you and  Osmium Consultancy, including any claims
        relating in any way to the present Agreement, any supplemental Terms, or the services, amendments, or any other
        aspects of the Agreement.<br>
        <span style="color: red; font-size: xx-large; font-weight: bold;">BINDING INDIVIDUAL ARBITRATION</span><br>
        <b>A.</b> You and  Osmium Consultancy agree to arbitrate any and all disputes by a neutral arbitrator appointed by us who has the
        power
        to award the same damages and relief that a court can.<br><br>

        <b>B.</b> Any arbitration under these general terms will only be on an individual basis.<br><br>

        <b>C.</b> Class arbitrations, class actions, private attorney general actions, representative actions and
        consolidation
        with other arbitrations are not permitted.<br><br>

        <b>D.</b> You waive any right to have your case decided by a jury and further waive any right to participate in
        a class
        action against Osmium Consultancy<br><br>

        <b>E.</b> If any provision of this arbitration agreement is found unenforceable, the unenforceable provision
        will be
        severed, and the remaining arbitration terms will be enforced (but in no case, will there be a class or
        representative arbitration).<br><br>

        <b>F.</b> All disputes will be resolved finally and exclusively by binding individual arbitration with a single
        arbitrator administered by the provisions of Arbitration and Conciliation Act, 1996.<br><br>

        <b>G.</b> Any arbitration hearing will occur in India, or another mutually agreeable location.<br><br>

        <b>Powers of Arbitrator</b>: The arbitrator and any federal, state, or local court or agency, shall have
        exclusive
        authority to resolve any dispute relating to the interpretation, applicability, enforceability or formation of
        this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration
        Agreement is void or voidable. The arbitration will decide the rights and liabilities if any, of you and the Osmium Consultancy
        The arbitration proceeding will not be consolidated with any other matters or joined with any other proceedings
        or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or
        dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary remedy
        or relief available to an individual under applicable law, the arbitral forum’s rules, and this Agreement
        (including this Arbitration Agreement). The arbitrator will issue a written statement of decision describing the
        essential findings and conclusions on which any award (or decision not to render an award) is based, including
        the calculation of any damages awarded. The arbitrator shall follow the applicable law. The arbitrator has the
        same authority to award relief on an individual basis that a judge in a court of law would have. The
        arbitrator’s decision is final and binding on you and the Osmium Consultancy
        <br><br>

        <span class="card-internal-heading">25. Other Provisions</span><br><br>
        <b>25.1</b>  Osmium Consultancy may give notice by means of a general notice on the Services via electronic mail to your email
        address, you may give notice to  Osmium Consultancy by written communication to our address at office House no 67 , Sargam
        society , Lane 2, Warje jakatnaka, Near vittal mandir , Hira kirana store, Pune, Maharashtra ,411052 or via
        electronic mail to our email address contact.shopactreg@gmail.com
        <br> <!-- TODO Change Address -->

        <b>23.2</b> You may not assign or transfer these Terms in whole or in part without  Osmium Consultancy’s prior written approval.
        You give your approval to  Osmium Consultancy for it to assign or transfer these Terms in whole or in part, including to (i) a
        subsidiary or affiliate; (ii) an acquirer of Osmium Consultancy equity, business or assets; or (iii) a successor by merger. No
        joint venture, partnership, employment or agency relationship exists between you, Osmium Consultancy or any third-party provider
        as a result of the contract between you and Osmium Consultancy for use of the Services.
        <br>

        <br><br>

        <span class="card-internal-heading">26. Updates to services</span><br><br>
        <b>26.1</b>  Osmium Consultancy may from time to time provide enhancements or improvements to the features/functionality of
        services, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").<br>

        <b>26.2</b> Updates may modify or delete certain features and/or functionalities of the services. You agree that
         Osmium Consultancy has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features
        and/or functionalities of services to you.<br>

        <b>26.3</b> You further agree that all Updates will be (i) deemed to constitute an integral part of the
        services, and (ii) subject to the Terms of this Agreement.<br>
        <br><br>

        <span class="card-internal-heading">27. No Changes in Terms at your request</span><br><br>
        <b>27.1</b> The terms mentioned herein shall not be changed by any one of you or your group. The same terms and
        conditions of use shall be applicable for all users.
        <br>
        <br><br>

        <span class="card-internal-heading">28. Contact Information</span><br><br>
        <b>28.1</b>  Osmium Consultancy welcomes your questions or comments regarding the Terms: contact us at +91 96896 45226
        <br>
        <br><br>

      </div>
    </v-card>
    <br>
    <v-divider></v-divider>
    <br>
    <Footer></Footer>
    <div class="text-center">
    </div>
  </v-app>

</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
  name: "tc",
  components: {Footer, Header}
}
</script>

<style scoped>

.content {
  text-align: center;
}

.card {
  max-width: 65vw;
  position: relative;
  left: 18%;
  padding: 10px;
}

.card-internal-heading {
  font-size: x-large;
  font-weight: bold;
}

.main_heading{
  font-size: xx-large;
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {

  .card {
    max-width: 95vw;
    position: relative;
    left: 4%;
    padding: 10px;
  }

  .card-internal-heading {
    font-size: large;
  }

  .main_heading{
    font-size: larger;
  }

}

</style>